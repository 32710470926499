import { FormContainer } from '../auth/AuthCommon';
import {
  AttFileControllerService,
  PaymentMasterInfoVo,
  SupportModuleControllerService,
} from '@/__generated__/CommonApi';
import ImgSchoolSupportReceipt from '@/assets/img/donate/school-support-receipt.jpg';
import ImgSchoolSupportTestimonial from '@/assets/img/donate/school-support-testimonial.jpg';
import IconQuestion from '@/assets/img/icons/icon_questionmark@2x.png';
import Attachment from '@/components/Attachment';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputTextarea from '@/components/Input/InputTextarea';
import Section from '@/components/Section';
import {
  IconNotiSub,
  IconNotiText,
  IconNotiTitle,
} from '@/components/Text/TextIconNoti';
import Tooltip from '@/components/Tooltip';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { usePopupStore } from '@/stores/PopupStore';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const QuestionImg = styled.img`
  margin-left: 8px;
  cursor: pointer;
  width: 24px;
  vertical-align: top;
`;

const SectionBg = styled(Section)`
  padding-top: 32px !important;
  padding-bottom: 240px !important;
  background: #f8f9fa;
  border-bottom: 1px solid #e5e6e8;
`;

const LabelBold = styled(Label)`
  margin-bottom: 16px;
`;
const TooltipCheck = styled.div`
  display: flex;
  justify-content: space-between;
  & + div {
    margin-top: 16px;
  }
  ${breakpoint(`mobile`)} {
    ${Button} {
      :first-child {
        margin-right: 12px;
      }
      :last-child {
        margin-left: 12px;
      }
    }
  }
`;

interface SchoolFormData {
  content: string;
  isReqTax: boolean;
  isReqThx: boolean;
}

interface SchoolFormProps {
  order: PaymentMasterInfoVo;
}

const DonateCompleteSchoolForm: FC<SchoolFormProps> = observer(({ order }) => {
  const popupStore = usePopupStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SchoolFormData>({
    defaultValues: {},
  });
  const [images, setImages] = useState<File[]>([]);

  // 전송
  const onSubmit: SubmitHandler<SchoolFormData> = useCallback(
    async (formData) => {
      try {
        let attGrpNo: any;
        // 파일이 있다면 업로드
        if (images.length > 0) {
          const result = await AttFileControllerService.uploadUsingPost({
            file: images,
          });
          attGrpNo = result.data.attGrpNo;
        }

        const {
          resultCode,
          resultMessage,
        } = await SupportModuleControllerService.addSchoolDonationUsingPost({
          giftId: order.giftId,
          attGrpNoActive: attGrpNo,
          content: formData.content,
          reqTax: formData.isReqTax ? `Y` : `N`,
          reqThx: formData.isReqThx ? `Y` : `N`,
        });

        if (resultCode === `success`) {
          popupStore.show(
            `활동 내용이 성공적으로 전달되었습니다.\n관리자 승인 후 후원 학교 소식에 노출됩니다.`,
          );
          reset();
          setImages([]);
        } else {
          popupStore.show(resultMessage);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [images, order.giftId, popupStore, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionBg>
        <FormContainer>
          <FormItem noBorder>
            <FormGroup border>
              <IconNotiText>
                <IconNotiTitle>
                  잠깐! 학교에서 모금활동을 진행하셨나요?
                </IconNotiTitle>
                <IconNotiSub>
                  활동 내용을 사진과 함께 보내주시면, &apos;후원 학교&apos;
                  소식에 실어 드립니다. <br />
                  긴급구호 모금인 경우, 어린이를 위한 응원 메시지를 내용에
                  기재해 주시기 바랍니다
                </IconNotiSub>
              </IconNotiText>
            </FormGroup>
            <FormGroup border>
              <LabelBold bold>활동 내용</LabelBold>
              <InputTextarea
                ref={register({
                  required: {
                    value: true,
                    message: `활동 내용을 입력해주세요.`,
                  },
                })}
                name="content"
                placeholder="언제, 어디서, 누가, 어떻게, 모금 활동을 했는지 알려주세요.
활동에 참여한 학생들이나 지도교사의 소감도 함께 알려주시면 더욱 좋습니다."
              />
              {errors.content && (
                <ErrorMessage>{errors.content.message}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup border>
              <LabelBold bold>활동 사진</LabelBold>
              <Attachment
                maxFileCount={1}
                files={images}
                onFilesChange={setImages}
                accept="image/*"
              />
            </FormGroup>
            <FormGroup>
              <LabelBold bold>영수증 및 감사장</LabelBold>
              <TooltipCheck>
                <div>
                  영수증
                  <Tooltip
                    tip={
                      <img
                        src={ImgSchoolSupportReceipt}
                        alt="영수증 미리보기"
                      />
                    }
                    size={300}
                    position="top"
                  >
                    <QuestionImg src={IconQuestion} />
                  </Tooltip>
                </div>
                <InputCheckbox ref={register} label="신청" name="isReqTax" />
              </TooltipCheck>
              <TooltipCheck>
                <div>
                  감사장
                  <Tooltip
                    tip={
                      <img
                        src={ImgSchoolSupportTestimonial}
                        alt="감사장 미리보기"
                      />
                    }
                    size={300}
                    position="top"
                  >
                    <QuestionImg src={IconQuestion} />
                  </Tooltip>
                </div>
                <InputCheckbox ref={register} label="신청" name="isReqThx" />
              </TooltipCheck>
            </FormGroup>
            <div
              css={`
                margin-top: 24px;
              `}
            >
              <Button type="submit" full>
                활동내용 보내기
              </Button>
            </div>
          </FormItem>
        </FormContainer>
      </SectionBg>
    </form>
  );
});

export default DonateCompleteSchoolForm;
