import IconTooltip from '@/assets/img/icons/icon_tooltip@2x.png';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

const TooltipText = styled.p`
  font-size: 14px;
  z-index: -99999;
  visibility: hidden;
  opacity: 0;
  border: 1px solid #1cabe2;
  background: #fff;
  z-index: 1;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  white-space: nowrap;
  transition: opacity 0.4s;
`;

interface TooltipBoxProps {
  type?: string;
  size?: number;
  position?: string;
}

const TooltipBox = styled.div<TooltipBoxProps>`
  display: inline-block;
  position: relative;
  &:hover {
    ${TooltipText} {
      z-index: 1;
      opacity: 1;
      visibility: visible;
    }
  }
  ${({ type }) => {
    if (type === `icon`) {
      return `
      padding-left: 28px;
      &::before {
        content: '';
        dosplay: block;
        width: 20px;
        height: 20px;
        background: url(${IconTooltip}) no-repeat center center;
        background-size: 100%;
        position: absolute;
        left: 0;
        top: 4px;
      }
      `;
    }
    return ``;
  }}
  ${({ size }) => {
    if (size) {
      return `
      ${TooltipText} {
        width: ${size}px;
        white-space: normal;
      }
      `;
    }
    return ``;
  }}
  ${({ position }) => {
    if (position === `top`) {
      return `
      ${TooltipText} {
        bottom: 100%;
      }
      `;
    }
    return ``;
  }}
`;

interface TooltipProps extends TooltipBoxProps {
  tip?: ReactElement;
}
const Tooltip: FC<TooltipProps> = ({ tip, children, ...props }) => (
  <>
    <TooltipBox {...props}>
      {children}
      <TooltipText>{tip}</TooltipText>
    </TooltipBox>
  </>
);

export default Tooltip;
