import {
  MemberJoinControllerService,
  PaymentControllerService,
  PaymentMasterInfoVo,
  SupportModuleControllerService,
} from '@/__generated__/CommonApi';
import BannerPartnership from '@/assets/img/banner/banner_partnerships_544@2x.png';
import ImagePayComplete from '@/assets/img/img_pay_complete@2x.png';
import Agreement from '@/components/Agreement';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import SuccessMessage from '@/components/Form/SuccessMessage';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import InputPassword from '@/components/Input/InputPassword';
import InputText from '@/components/Input/InputText';
import LinkSafe from '@/components/LinkSafe';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SupportPhrase from '@/components/SupportPhrase';
import {
  IconNotiSub,
  IconNotiText,
  IconNotiTitle,
} from '@/components/Text/TextIconNoti';
import { Tit } from '@/components/Titles';
import {
  HomepageAgreeContent,
  JoinPrivacyAgreeContent,
} from '@/data/agreements';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper, { PurchaseData } from '@/helpers/GtmHelper';
import {
  useFindSupportCategory,
  MEMBER_TYPE_CORPORATION,
  MEMBER_TYPE_HOSPITAL,
  MEMBER_TYPE_INDIVIDUAL,
  MEMBER_TYPE_INDIVIDUAL_BUSINESS,
  MEMBER_TYPE_RELIGION,
  MEMBER_TYPE_SCHOOL,
  SUPPORT_TERM_ONCE,
  SUPPORT_TERM_REGULAR,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ButtonGroup,
  FormContainer,
  FormButton,
} from '@/page-blocks/auth/AuthCommon';
import { DonateFormItem } from '@/page-blocks/donate/DonateCommon';
import DonateCompleteSchoolForm from '@/page-blocks/donate/DonateCompleteSchoolForm';
import SessionStorage from '@/services/SessionStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate, PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const SectionComplete = styled(Section)`
  .tit-form-item {
    padding-bottom: 40px;
  }
  .dec {
    margin-top: 16px;
  }

  ${breakpoint(`tablet`)} {
    .tit-form-item {
      padding-bottom: 24px;
    }
  }
`;

const PayCompleteImage = styled.div`
  ${Tit} {
    display: block;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #1cabe2;
  }
  img {
    max-width: 100%;
  }
`;
const PayDetail = styled.ul`
  padding: 24px 0 0 0;
  li {
    padding-left: 96px;
    position: relative;
    line-height: 28px;
    & + li {
      margin-top: 8px;
    }
  }
  span {
    color: #2d2926;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
  }
  td {
  }
`;

const PayDetailItem = styled.li``;
const RightCol = styled(Col)`
  padding-left: 32px;
  ${breakpoint(`mobile`)} {
    padding-left: 16px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 24px;

  ${breakpoint(`tablet`)} {
  }
`;

interface JoinFormData {
  userId: string;
  password: string;
  passwordConfirm: string;
  policyAgree: boolean;
}

const DonateComplete: FC<PageProps> = observer(({ location }) => {
  const popupStore = usePopupStore();
  const userStore = useUserStore();
  const qs = queryString.parse(location.search);
  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<JoinFormData>({
    mode: `onChange`,
  });

  const [order, setOrder] = useState<PaymentMasterInfoVo>();
  const [orderData, setOrderData] = useState<PurchaseData>();
  const [surveyLink, setSurveyLink] = useState(``);
  const selectedCategory = useFindSupportCategory(
    order?.donationCode,
    order?.solicitCode,
  );

  // 간편가입 가능여부
  const [showJoin, setShowJoin] = useState(false);
  const supportAmount = order ? Number(order.totalAmount) : 0;

  // 가입 가능여부 체크 (이메일 중복 체크)
  const checkJoinable = useCallback(async (orderInfo: PaymentMasterInfoVo) => {
    // 가입조건에 따라 이메일 가져오기
    const orderEmail =
      orderInfo.memberTypeCode === MEMBER_TYPE_INDIVIDUAL
        ? orderInfo.email
        : orderInfo.managerEmail;

    const {
      resultCode,
    } = await MemberJoinControllerService.duplicateCheckUserEmailUsingGet({
      email: orderEmail,
    });
    if (resultCode === `success`) {
      setShowJoin(true);
    }
  }, []);

  const loadOrder = useCallback(async () => {
    // 주문토큰 불러오기
    const orderToken = SessionStorage.getItem(`orderToken`);
    // 주문정보 불러오기
    const sessionOrderData = JSON.parse(SessionStorage.getItem('orderData'));
    if (!orderToken || !sessionOrderData) {
      popupStore.show(`후원 정보가 올바르지 않습니다`, {
        onConfirm: () => window.history.back(),
      });
    }
    // 세션 스토리지 주문정보 세팅
    setOrderData(sessionOrderData);
    // API 호출
    const { data } = await PaymentControllerService.getPaymentInfoUsingGet({
      orderNumber: qs.orderNumber as string,
      orderToken,
    });
    // 가져온 주문정보 세팅
    setOrder(data);
    // 회원가입 가능여부 체크
    checkJoinable(data);
  }, [checkJoinable, popupStore, qs.orderNumber]);

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  // 회원가입 관련
  // 중복확인 통과한 아이디 임시저장
  const [confirmedUserId, setConfirmedUserId] = useState<string>(``);

  const onSubmit: SubmitHandler<JoinFormData> = useCallback(
    async (data) => {
      if (!order) {
        return;
      }
      // 가입처리
      try {
        const {
          resultCode,
        } = await MemberJoinControllerService.memberJoinUsingPost({
          // 간편가입 플래그
          simepleMemberJoin: `Y`,
          donorId: order.donorId,
          // 가입정보
          authType: `sign`,
          password: data.password,
          userId: data.userId,
          birthday: order.birthday || ``,
          email:
            order.memberTypeCode === MEMBER_TYPE_INDIVIDUAL
              ? order.email
              : order.managerEmail,
          name: order.memberName,
          mobilePhoneNumber: order.mobilePhoneNumber,
        });
        if (resultCode === `success`) {
          popupStore.show(
            `회원가입이 완료되었습니다. 로그인 후 다양한 서비스를 이용해보세요.`,
            {
              onConfirm: (ok) => ok && navigate(`/auth/login`),
              showCancelButton: true,
            },
          );
        }
      } catch (e) {
        if (e.response) {
          popupStore.show(e.response.data.resultMessage);
        }
      }
    },
    [order, popupStore],
  );

  // 중복 확인
  const checkDuplicateId = useCallback(async () => {
    const userIdValue = getValues(`userId`);
    const {
      resultCode,
    } = await MemberJoinControllerService.duplicateCheckUserIdUsingGet({
      userId: userIdValue,
    });

    if (resultCode === `failed`) {
      setError(`userId`, {
        type: `duplicate`,
        message: `이미 등록된 아이디 입니다`,
      });
    } else if (resultCode === `success`) {
      // validation 에러 제거
      clearErrors(`userId`);
      // 체크한 아이디 저장
      setConfirmedUserId(userIdValue);
    }
  }, [clearErrors, getValues, setError]);

  // 로그인된 상태라면 회원정보 업데이트 (토큰 업데이트)
  useEffect(() => {
    if (userStore.isLoggedIn()) {
      userStore.refreshJwt();
    }
  }, [userStore]);

  // 만족도 조사 링크 로드
  const loadSurveyLink = useCallback(async () => {
    if (!order) {
      return;
    }
    const {
      data,
      resultCode,
    } = await SupportModuleControllerService.getSatisfactionSurveyLinkUsingGet({
      donorId: order.donorId,
      giftId: order.giftId,
      mobilePhoneNumber: order.mobilePhoneNumber,
      name: order.memberName,
    });
    if (resultCode === `success`) {
      setSurveyLink(data.surveyUrl);
    }
  }, [order]);

  // 정기후원일때, 만족도 조사 링크 로드
  useEffect(() => {
    if (order?.supportTypeCode === SUPPORT_TERM_REGULAR) {
      loadSurveyLink();
    }
  }, [loadSurveyLink, order?.supportTypeCode]);

  // 주문정보 로드되면 GTM 이벤트 전송
  useEffect(() => {
    if (order && orderData) {
      // gtm purchase
      GtmHelper.purchase({
        campaignName: orderData?.campaignName,
        campaignPagenum: orderData?.campaignPagenum,
        promotionName: orderData?.promotionName,
        promotionId: orderData?.promotionId,
        value: orderData?.value,
        items: orderData?.items,
        paymentType: orderData?.paymentType,
        transactionId: order?.orderNumber,
      });
    }
  }, [order, orderData, selectedCategory]);

  return (
    <LayoutWithTitle location={location} title="후원완료" hideGnb paddingBtt>
      {order && (
        <SectionComplete
          css={`
            margin: 120px 0 0 0;
          `}
        >
          <FormContainer>
            <SectionHeader className="with-desc">
              <Tit size="s1-5" color="sky" weight="normal">
                후원이 완료되었습니다.
              </Tit>
            </SectionHeader>
            <FormItem>
              <PayCompleteImage>
                <Tit size="s2">후원신청 내역</Tit>
                <img src={ImagePayComplete} alt="" />
              </PayCompleteImage>
              {order && (
                <PayDetail>
                  <PayDetailItem className="ellipsis">
                    <span>후원 방법</span>
                    {order.supportTypeCode === SUPPORT_TERM_REGULAR && `정기`}
                    {order.supportTypeCode === SUPPORT_TERM_ONCE && `일시`}
                  </PayDetailItem>
                  <PayDetailItem>
                    <span>후원 분야</span>
                    {selectedCategory?.codeName}
                  </PayDetailItem>
                  <PayDetailItem>
                    <span>후원 금액</span>
                    {order.supportTypeCode === SUPPORT_TERM_REGULAR && `매월 `}
                    {supportAmount.toLocaleString()}원
                  </PayDetailItem>
                  <PayDetailItem>
                    <span>결제 정보</span>
                    {order.paymentTypeName}
                    {` `}
                    <br />
                    {order.payName} {order.payNo}
                  </PayDetailItem>
                </PayDetail>
              )}
            </FormItem>
            <DonateFormItem>
              <Tit
                size="s3"
                css={`
                  strong {
                    color: #1cabe2;
                  }
                  margin-bottom: 20px;
                `}
              >
                {order?.supportTypeCode === SUPPORT_TERM_REGULAR && (
                  <SupportPhrase supportAmount={supportAmount} />
                )}
                {order?.supportTypeCode === SUPPORT_TERM_ONCE &&
                  `보내주신 후원금은 전 세계 어린이를 위해 소중하게 사용하겠습니다.`}
              </Tit>
            </DonateFormItem>
            {!userStore.isLoggedIn() && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <DonateFormItem>
                  <FormGroup>
                    <Tit size="s3">
                      아직 <span className="ellipsis">유니세프</span> 웹 회원이
                      아니신가요?
                    </Tit>
                    <p className="dec">
                      간단한 정보 입력만으로 후원내역 관리, 기부금 영수증 출력
                      등 다양한 개인 서비스를 이용하실 수 있습니다.
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label required>아이디</Label>
                    <Row>
                      <Col desktop="2">
                        <InputText
                          name="userId"
                          ref={register({
                            required: true,
                            minLength: {
                              value: 8,
                              message: `8자 이상이어야 합니다`,
                            },
                            maxLength: {
                              value: 16,
                              message: `16자 이하여야 합니다`,
                            },
                            pattern: {
                              value: /^[a-z0-9-_]+$/,
                              message: `영문 소문자, 숫자, (-)(_)만 사용가능합니다`,
                            },
                            validate: (value) =>
                              (value && confirmedUserId === value) ||
                              `중복확인이 필요합니다`,
                          })}
                          placeholder="8-16자의 영문 소문자, 숫자, (-)(_)"
                          autoComplete="off"
                        />
                      </Col>
                      <RightCol desktop="none">
                        <FormButton
                          outline
                          onClick={checkDuplicateId}
                          disabled={
                            !getValues(`userId`) ||
                            (!!errors.userId &&
                              errors.userId.type !== `validate`) ||
                            confirmedUserId === getValues(`userId`)
                          }
                        >
                          중복확인
                        </FormButton>
                      </RightCol>
                    </Row>
                    {errors.userId && (
                      <ErrorMessage>{errors.userId.message}</ErrorMessage>
                    )}
                    {getValues(`userId`) && !errors.userId && (
                      <SuccessMessage>사용가능한 아이디 입니다</SuccessMessage>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label required>비밀번호</Label>
                    <InputPassword
                      name="password"
                      ref={register({
                        required: true,
                        minLength: {
                          value: 8,
                          message: `8자 이상이어야 합니다`,
                        },
                        maxLength: {
                          value: 16,
                          message: `16자 이하여야 합니다`,
                        },
                        pattern: {
                          value: /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*?&^()+=-])/,
                          message: `영문 및 숫자,특수문자를 모두 포함해야 합니다`,
                        },
                      })}
                      placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
                    />
                    {errors.password && (
                      <ErrorMessage>{errors.password.message}</ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label required>비밀번호 확인</Label>
                    <InputPassword
                      name="passwordConfirm"
                      ref={register({
                        required: true,
                        validate: (value) =>
                          value === getValues(`password`) ||
                          `비밀번호가 일치하지 않습니다`,
                      })}
                      placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
                    />
                    {errors.passwordConfirm && (
                      <ErrorMessage>
                        {errors.passwordConfirm.message}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Agreement
                      name="policyAgree"
                      allChk
                      ref={register({
                        required: {
                          value: true,
                          message: `이용약관에 동의해주세요`,
                        },
                      })}
                      policies={[
                        {
                          label: `웹사이트 이용약관 동의`,
                          content: HomepageAgreeContent,
                          required: true,
                        },
                        {
                          label: `개인정보 수집 ∙ 이용 동의`,
                          content: JoinPrivacyAgreeContent,
                          required: true,
                        },
                      ]}
                    />
                    {errors.policyAgree && (
                      <ErrorMessage>{errors.policyAgree.message}</ErrorMessage>
                    )}
                  </FormGroup>
                  <ButtonContainer>
                    <Button type="submit" full>
                      간편 회원가입
                    </Button>
                  </ButtonContainer>
                </DonateFormItem>
              </form>
            )}
            {surveyLink && (
              <DonateFormItem noBorder>
                <IconNotiText>
                  <IconNotiTitle
                    css={`
                      margin-bottom: 8px;
                    `}
                  >
                    유니세프와 함께 한 후원이 어떠셨나요?
                  </IconNotiTitle>
                  <IconNotiSub>
                    후원자님의 의견에 귀 기울이고, 더 나은 후원관리 프로그램을
                    제공하기 위해 간단한 설문조사를 요청드립니다. 감사합니다.
                  </IconNotiSub>
                </IconNotiText>
                <ButtonContainer>
                  <Button
                    type="button"
                    full
                    onClick={() => window.open(surveyLink)}
                  >
                    만족도 조사
                  </Button>
                </ButtonContainer>
              </DonateFormItem>
            )}
          </FormContainer>
        </SectionComplete>
      )}
      <Section>
        <FormContainer>
          <FormItem noBorder>
            {/* 아래 영역은 '법인/단체' 중 기업, 병원, 개인사업자, 종교단체 후원인 타입만 출력 */}
            {[
              MEMBER_TYPE_CORPORATION,
              MEMBER_TYPE_HOSPITAL,
              MEMBER_TYPE_INDIVIDUAL_BUSINESS,
              MEMBER_TYPE_RELIGION,
            ].includes(order?.memberTypeCode) && (
              // 배너 표시
              <LinkSafe to="/involve/corporate-and-organization/social-contribution">
                <img
                  src={BannerPartnership}
                  alt=""
                  css={`
                    margin: 120px 0 0 0;
                  `}
                />
              </LinkSafe>
            )}
            <ButtonGroup
              css={`
                margin: 46px 0;
              `}
            >
              <Row>
                <Button full outline onClick={() => navigate(`/`)}>
                  홈으로 이동
                </Button>
                {order?.supportTypeCode === SUPPORT_TERM_REGULAR && (
                  <Button full onClick={() => navigate(`/mypage/support-info`)}>
                    후원 및 결제정보
                  </Button>
                )}
                {order?.supportTypeCode === SUPPORT_TERM_ONCE && (
                  <Button
                    full
                    onClick={() => navigate(`/mypage/support-history`)}
                  >
                    나의 후원내역
                  </Button>
                )}
              </Row>
            </ButtonGroup>
          </FormItem>
        </FormContainer>
      </Section>
      {/* 학교후원의 경우에만 출력 */}
      {order?.memberTypeCode === MEMBER_TYPE_SCHOOL && (
        <DonateCompleteSchoolForm order={order} />
      )}
    </LayoutWithTitle>
  );
});
export default DonateComplete;
