import IconNoti from '@/assets/img/icons/icon_text_noti@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const IconNotiText = styled.div``;
export const IconNotiTitle = styled.strong`
  display: block;
  position: relative;
  padding-left: 32px;
  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(${IconNoti}) no-repeat center center;
    background-size: 20px;
    position: absolute;
    left: 0;
    top: 3px;
  }
`;
export const IconNotiSub = styled.p`
  padding-left: 32px;
  line-height: 32px;
  ${breakpoint(640)} {
    line-height: normal;
  }
`;
