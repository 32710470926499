import { FormItem } from '@/components/Form/FormGroup';
import styled from 'styled-components';

export const LabelFlex = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 22px;
`;

export const DonateFormItem = styled(FormItem)`
  padding-top: 24px;
  padding-bottom: 40px;
`;
